@font-face {
  font-family: 'AlmarenaDisplay';
  src: url('/fonts/almarena/Almarena-Display-Light.otf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'AlmarenaDisplay';
  src: url('/fonts/almarena/Almarena-Display-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'AlmarenaDisplay';
  src: url('/fonts/almarena/Almarena-Display-Bold.otf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'November Compressed Heavy';
  src: url('/fonts/november-compressed-heavy/november-compressed-heavy.woff2') format('woff2'),
    url('/fonts/november-compressed-heavy/november-compressed-heavy.woff') format('woff');
}
/* Barlow */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/barlow/Barlow-Regular.woff2') format('woff2'),
    url('/fonts/barlow/Barlow-Regular.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/barlow/Barlow-Medium.woff2') format('woff2'),
    url('/fonts/barlow/Barlow-Medium.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/barlow/Barlow-SemiBold.woff2') format('woff2'),
    url('/fonts/barlow/Barlow-SemiBold.woff') format('woff');
  font-display: swap;
}
/* barlow-700 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/barlow/Barlow-Bold.woff2') format('woff2'),
    url('/fonts/barlow/Barlow-Bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/barlow/Barlow-ExtraBold.woff2') format('woff2'),
    url('/fonts/barlow/Barlow-ExtraBold.woff') format('woff');
  font-display: swap;
}
/* Barlow Condensed */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/barlow-condensed/BarlowCondensed-SemiBold.woff2') format('woff2'),
    url('/fonts/barlow-condensed/BarlowCondensed-SemiBold.woff') format('woff');
  font-display: swap;
}
/* Satoshi */

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('/fonts/satoshi/Satoshi-Variable.woff2') format('woff2'),
       url('/fonts/satoshi/Satoshi-Variable.woff') format('woff');
       font-weight: 300 900;
       font-display: swap;
       font-style: normal;
}

